import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import App from '../App'
import Chapter0 from '../pages/chapter_0';
import Chapter1 from '../pages/chapter_1';
import Chapter2 from '../pages/chapter_2';
import Chapter3 from '../pages/chapter_3';
import Chapter4 from '../pages/chapter_4';
import Chapter5 from '../pages/chapter_5';
import Chapter6 from '../pages/chapter_6';
import Chapter7 from '../pages/chapter_7';
import Chapter8 from '../pages/chapter_8';
import Chapter9 from '../pages/chapter_9';
import Chapter10 from '../pages/chapter_10';
import Chapter11 from '../pages/chapter_11';
import NotFound from '../pages/NotFound';


export default function Routing() {
    return (
    <Router>
        <Switch>
            <Route exact path="/" component={App} />
            <Route exact path="/chapter-0" component={Chapter0} />
            <Route exact path="/chapter-1" component={Chapter1} />
            <Route exact path="/chapter-2" component={Chapter2} />
            <Route exact path="/chapter-3" component={Chapter3} />
            <Route exact path="/chapter-4" component={Chapter4} />
            <Route exact path="/chapter-5" component={Chapter5} />
            <Route exact path="/chapter-6" component={Chapter6} />
            <Route exact path="/chapter-7" component={Chapter7} />
            <Route exact path="/chapter-8" component={Chapter8} />
            <Route exact path="/chapter-9" component={Chapter9} />
            <Route exact path="/chapter-10" component={Chapter10} />
            <Route exact path="/chapter-11" component={Chapter11} />

            <Route component={NotFound} />
        </Switch>
    </Router>
    );
}
