import React from 'react';
import ShareIt from './shareit';
import { chapters } from './listIndex';

const chaptersWithoutTitle = ["0", "10", "11"];

function getBreadCrumb (chapter) {
    if (!chapter) {
        return <a href="/">PWA Book 2</a>;
    }

    if (chaptersWithoutTitle.includes(chapter)) {
        return chapters[chapter].title;
    }

    return 'Chapter '+chapter;
}


export default ({ chapter }) => {
    const breadCrumb = getBreadCrumb(chapter);

    return (
        <div className="breadcrumb">
            <div className="box-left">
                <strong><a href="/">PWA EBOOK 2</a></strong> / {breadCrumb}
            </div>
            <div className="box-right">
                <ShareIt chapter={chapter} />
            </div>
        </div>
    );
};
