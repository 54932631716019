import React from 'react';

import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;

    return (
        <Layout>
            <div className="block-content" id="chapter-1" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="1" />
                        <div className="entry">
                            <h2>Installable Progressive Web Apps</h2>
                            <div className="by">By Daniil Matveev</div>
                            <h3>The web has two distinguishing superpowers</h3>
                            <ol>
                                <li>The widest reach of any platform, spanning every kind of device and screen size</li>
                                <li>Links that enable search indexing for organic discovery and trivial sharing of content and services between users</li>
                            </ol>
                            <p>These superpowers have made the web unstoppable as a platform on desktop. For example, productivity apps and suites such as Google’s G Suite, Microsoft Office 365, Trello, Slack, Discord, InVision, and Lucidchart have been around for a long time as desktop web apps.</p>
                            <p>However, on mobile, instead of the web, native app stores are often thought of as the go-to app platform for both users and developers. Why is this? From a developer perspective, many of the capabilities that require deep integration into the underlying OS were previously unavailable on the web.</p>
                            <p>Nowadays, the web natively supports almost any capability that an application might need, including:&nbsp;</p>
                            <ul>
                                <li><a href="https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API">Media &amp; streaming media access</a>, <a href="https://developers.google.com/web/fundamentals/media/capturing-images">capturing photos</a> &amp; <a href="https://developers.google.com/web/fundamentals/media/recording-video">videos from any camera on the device</a></li>
                                <li><a href="https://web.dev/shape-detection/">Shape detection including barcode </a>&amp;<a href="https://web.dev/shape-detection/"> face detection</a></li>
                                <li><a href="https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications">Push notifications</a></li>
                                <li><a href="https://developer.mozilla.org/en-US/docs/Web/API/Credential_Management_API">Biometric authentication, including face </a>&amp;<a href="https://developer.mozilla.org/en-US/docs/Web/API/Credential_Management_API"> fingerprint</a></li>
                                <li><a href="https://developers.google.com/web/updates/2015/07/interact-with-ble-devices-on-the-web">Bluetooth</a> &amp; <a href="https://www.chromium.org/developers/design-documents/web-nfc">NFC</a></li>
                                <li><a href="https://web.dev/contact-picker/">Native access to contacts</a></li>
                                <li><a href="https://web.dev/native-file-system/">Native filesystem access</a></li>
                                <li><a href="https://developers.google.com/web/updates/2018/03/clipboardapi">Raw clipboard access</a></li>
                                <li><a href="https://developers.google.com/web/fundamentals/native-hardware/device-orientation">Device motion and orientation</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/API/Vibration_API">vibration</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation">screen orientation (including lock)</a></li>
                            </ul>
                            <p>And much, much more… be sure to check out the <a href="https://www.chromium.org/teams/web-capabilities-fugu">Web Capabilities (Fugu) project</a> for a full list of what’s cooking.</p>
                            <h3>The superpowers of the web and native applications combined</h3>
                            <p>Progressive Web Apps combine the reach and linkability of the web with the device integrations of native applications. Many businesses have already started taking advantage of PWAs to provide great user experiences. For example:</p>
                            <ul>
                                <li>Taxi apps like Ola Cabs, Lyft and Uber, are using web push notifications to alert their passengers about ride statuses.&nbsp;</li>
                                <li>Retailers and Banks are embracing offline browsing experience for those “whoops, I thought there’d be Wi-Fi on the train” moments.&nbsp;</li>
                                <li>Food delivery services, such as Delivery Club, are deploying PWAs due to ease of maintenance and cross-platform availability.</li>
                            </ul>
                            <p>PWAs can be discovered in the web browser and used immediately. Content from PWAs can be shared with a URL, and the person receiving the URL can access the content whether they have the PWA installed or not.&nbsp;</p>
                            <p>If the user chooses, they can install the PWA<em>, </em>and get the device integrations they expect from a native app, such as:&nbsp;</p>
                            <ul>
                                <li>Accessible from familiar discovery &amp; launch surfaces for their device, like the homescreen and the app tray.&nbsp;</li>
                                <li>Standalone experience, separate from the browser</li>
                                <li>Native device services such as the app switcher and settings,</li>
                            </ul>
                            <p>In addition to installing from the browser, PWAs can also be installed from App Stores including Google Play, Samsung Galaxy Store and the Microsoft store on Windows 10.&nbsp;</p>
                            <p>In the chapters ‘<a href="/chapter-3">Requirements for Installability</a>’ and ‘<a href="/chapter-4">Patterns for Promoting PWA Install from the Browser</a>’ more detail on the technical requirements and design patterns for installation will be provided. For now, here is a brief overview of the install experience from the Browser on&nbsp; Mobile and Desktop and from App stores.</p>
                            <h3>Install from the Browser</h3>
                            <p>As of early 2020, Progressive Web App install is supported by all major browsers, although the install experience varies.&nbsp;</p>
                            <p><strong>Mobile</strong></p>
                            <ul>
                                <li>Chrome &amp; Edge on Android offer full control <a href="https://web.dev/customize-install/">over the install prompt experience</a> (also sometimes referred to as “Add to Home Screen” or A2HS).&nbsp;&nbsp;</li>
                                <li><a href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Installable_PWAs">Firefox</a>, <a href="https://hub.samsunginter.net/docs/ambient-badging/">Samsung Internet</a>, and <a href="https://dev.opera.com/blog/installable-web-apps/">Opera </a>users can trigger install by tapping on an address bar icon.&nbsp;</li>
                                <li>Safari on iOS requires users to manually install PWAs from the actions menu.</li>
                            </ul>
                            <div className="grid">
                                <div className="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image2.webp" type="image/webp" />
                                          <source srcSet="images/content/image2.png" type="image/png" /> 
                                          <img src="images/content/image2.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Default experience on Chrome Android - see the mini info-bar at the bottom of the screen</figcaption>
                                    </figure>
                                </div>
                                <div className="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image19.webp" type="image/webp" />
                                          <source srcSet="images/content/image19.png" type="image/png" /> 
                                          <img src="images/content/image19.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Default experience on Android Firefox - see the ‘house’ icon which includes a plus symbol in the browser bar</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Desktop</strong></p>
                            <p>Desktop PWAs can be installed from Chrome and Edge on all operating systems, including Chrome OS, Linux, Mac, and Windows.&nbsp;</p>
                            <p>Users can tap an install icon in the address bar. Once installed, desktop PWAs can be launched from the App tray into a standalone experience.</p>
                            <figure>
                                <picture>
                                  <source srcSet="images/content/image23.webp" type="image/webp" />
                                  <source srcSet="images/content/image23.png" type="image/png" /> 
                                  <img src="images/content/image23.webp" alt="pwa book" />
                                </picture>
                                <figcaption>Chrome Desktop Install Prompt - see the plus symbol inside the circle on the right hand side</figcaption>
                            </figure>
                            <h3>Install from App Stores</h3>
                            <ul>
                                <li><strong>Google Play Store.</strong> <a href="https://developers.google.com/web/updates/2019/02/using-twa">Trusted Web Activities (TWA)</a> allow Progressive Web Apps to be published in the Google Play Store. This allows users to discover Progressive Web Apps alongside any other app listed</li>
                                <li><strong>Samsung Galaxy Store.</strong> As of early 2020, Samsung is using the following process: send the URL to <a href="mailto:pwasupport@samsung.com">pwasupport@samsung.com</a> and Samsung will respond to start the process of getting the PWA added into Galaxy Store, including all the necessary licensing agreements</li>
                            </ul>
                        </div>
                        <ShareIt chapter="1" />
                        <ButtonsChapters next="chapter-2" previous="chapter-0" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
