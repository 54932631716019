import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'


export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-2" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="2" />
                        <div className="entry">
                            <h2>Product Strategies for Progressive Web Apps</h2>
                            <div className="by">By Daniil Matveev</div>
                            <p>Businesses often report that native app users are the most valuable and loyal, with a higher customer lifetime value (LTV) when compared to web users.</p>
                            <p>Are native app experiences so much better than web experiences, or is it simply that the most loyal users are more likely to install an app? Data from various businesses across the globe suggest the latter.&nbsp;</p>
                            <blockquote>
                                <p>The successful fashion online shop ABOUT YOU deployed a Progressive Web App and offered install from the browser, in addition to their native application which is available in Play. They found that users who had installed the PWA “<a href="https://www.thinkwithgoogle.com/intl/de-de/insights/markteinblicke/wie-about-you-mit-progressive-web-apps-die-mobile-performance-pusht/">generate sales five times higher than the average mobile user</a>” and that they “<a href="https://www.thinkwithgoogle.com/intl/de-de/insights/markteinblicke/wie-about-you-mit-progressive-web-apps-die-mobile-performance-pusht/">behave similarly to users of our native app</a>”</p>
                            </blockquote>
                            <p>Progressive Web Apps can provide a great experience to users who would never install a native app. Installed PWAs generally weigh less than 1 MB, which is far less than the average size of a native application. And building with the web makes it easier to support the long tail of different screen sizes and device capabilities.&nbsp;</p>
                            <p>These features are particularly important for businesses with growth ambitions in emerging markets, where data plans are expensive, storage space is limited and devices can be low powered.</p>
                            <p>In developed markets, there is a wide range of devices and more people on high end flagship phones. However, storage space still matters: the more we have, the more we use. In these markets, a PWA can be a complement to a native app, in particular for users that have churned from a native app to free up storage.</p>
                            <blockquote>
                                Remember that even in developed markets, there are a large number of people on mid-low tier devices. A PWA can help you reach everyone, in a cost effective manner.
                            </blockquote>
                            <p>While acknowledging the benefits that PWAs offer, businesses might still have concerns about how to best to deploy them in a manner that complements their existing native applications.&nbsp;</p>
                            <p>The following 4 strategies reflect how businesses with various needs can use PWAs to offer the best possible experience to the largest number of users, in the most cost effective manner.&nbsp;</p>
                            <div className="box-featured">
                                <h3>Strategy 1. All-in on web</h3>
                                <p>Go all in and rely solely on a Progressive Web App to deliver a great experience to all users, cost effectively. With one code base and design, resources can be focused on building new features and experiences.</p>
                                <p>For businesses that:</p>
                                <ul>
                                    <li>Don’t have a native app</li>
                                    <li>Or have a poor native app experience</li>
                                    <li>Or have a native app that relies heavily on web content</li>
                                </ul>
                                <p><strong>Development:</strong></p>
                                <ul>
                                    <li>Build a PWA&nbsp;</li>
                                    <li>Publish the PWA in Play using Trusted Web Activities. This may be the most cost-effective solution for web-heavy businesses looking to expand into the Play Store</li>
                                </ul>
                                <p><strong>Promotion:</strong></p>
                                <ul>
                                    <li>Browser: Promote PWA Install to all users</li>
                                    <li>Play: Publish the PWA in Play using TWA</li>
                                </ul>
                                <p><strong>Pros:</strong></p>
                                <p>✔ Allows businesses that don’t have an Android app to offer an installable experience</p>
                                <p>✔ Can replace an outdated Android app that barely receives any updates or has a poor user experience <strong>Tip.</strong> It can be especially easy to replace a dated hybrid app with a PWA</p>
                                <p>✔ Consolidate all mobile development into one single code-base</p>
                                <p><strong>Cons:</strong></p>
                                <p><span role="img" aria-label="cross">❌</span> Not suitable if functionality not available on the web is required</p>
                            </div>
                            <div className="box-featured">
                                <h3>Strategy 2: Complement Native with a ‘Lite App’</h3>
                                <p>The terms ‘Lite’ and ‘Go’ were coined to differentiate the lighter, faster, however sometimes less specialized web-based experience from that of a native app, for those businesses that are looking to offer both.&nbsp;</p>
                                <p>For businesses that already have a great native app, a Progressive Web App with ‘Lite’ branding can and help reach users who would never install the native app, such as those on mid-low end devices.&nbsp;</p>
                                <p><strong>Development:</strong></p>
                                <ul>
                                    <li>Build a Progressive Web App&nbsp;</li>
                                    <li>Publish a PWA in Play using Trusted Web Activities. Use ‘Lite’ naming to differentiate from the native application</li>
                                    <li>Build native applications</li>
                                </ul>
                                <p><strong>Promotion:</strong></p>
                                <ul>
                                    <li>Browser: Promote native app install to users. If they decline, promote the PWA using a ‘Lite’ value proposition</li>
                                    <li>Play: Publish the PWA in Play using TWA, and use ‘Lite’ naming so users can differentiate from the native app, and choose their preferred experience</li>
                                </ul>
                                <p><strong>Pros:</strong></p>
                                <p>✔ Ideal for businesses that have a great, but “heavy” native Android app, and are looking to offer better experience for users of mid-low-end devices and/or users in poor internet connectivity areas</p>
                                <p><strong>Cons:</strong></p>
                                <p><span role="img" aria-label="cross">❌</span> Requires managing 2 listings in Play, and using analytics to thoughtfully segment users for promotion of PWA or Native App from the browser</p>
                            </div>
                            <div className="box-featured">
                                <h3>Strategy 3: Separate Apps for Separate Tasks</h3>
                                <p>For many businesses, a Progressive Web App can provide the primary experience covering main conversion goals, while a native app might offer added value native services tailored only for specific use cases and offer specialized functionality.&nbsp;</p>
                                <p>For example:&nbsp;</p>
                                <ul>
                                    <li>A retailer might offer the main shop experience as a PWA, and a separate magazine/lifestyle native app</li>
                                    <li>An insurance company might offer the main information and lead generation as a PWA, and a separate chatbot/assistive experience/customer service native app&nbsp;</li>
                                </ul>
                                <p><strong>Development:</strong></p>
                                <ul>
                                    <li>Build a Progressive Web App</li>
                                    <li>Publish a PWA in Play using Trusted Web Activities</li>
                                    <li>Also build and maintain added value native apps</li>
                                </ul>
                                <p><strong>Promotion:</strong></p>
                                <ul>
                                    <li>Browser: Promote the PWA to all users. Only offer the specialized native app to those users who have already completed certain trigger conversion goals and would benefit from the additional features</li>
                                    <li>Play: Have separate Play store entries for the PWA published using TWA and the specialized native app, making clear in the listings what is available in each app</li>
                                </ul>
                                <p><strong>Pros:</strong></p>
                                <p>✔ Most suitable for businesses that have straightforward conversion goals and only require a native application for niche services</p>
                                <p><strong>Cons:</strong></p>
                                <p><span role="img" aria-label="cross">❌</span> Not suitable for businesses that don’t have added value services</p>
                            </div>
                            <div className="box-featured">
                                <h3>Strategy 4: Low Friction, High Installed User Base</h3>
                                <p>For businesses that can offer a comparable experience across native applications and Progressive Web Apps, one way to grow the total installed user base is to remove as much install friction as possible.&nbsp;</p>
                                <p>From the browser, the least install friction is PWA install, as it only takes 2 taps and users don’t need to leave the browser at all. From Play, having just one listing, the native application, means the user doesn’t need to choose between different versions.</p>
                                <p><strong>Development:</strong></p>
                                <ul>
                                    <li>Build a Progressive Web App</li>
                                    <li>Build native applications</li>
                                </ul>
                                <p><strong>Promotion:</strong></p>
                                <ul>
                                    <li>Browser: Promote the PWA to all users, except those who already have the native app installed <strong>Tip.</strong> Use the getInstalledRelatedApps API to promote install of your PWA only to users that don’t have the native app installed. See <a href="https://web.dev/get-installed-related-apps/" target="_blank" rel="noopener noreferrer">https://web.dev/get-installed-related-apps/</a> for details</li>
                                    <li>Play: only list the native application</li>
                                </ul>
                                <p><strong>Pros:</strong></p>
                                <p>✔ Ideal to reduce install friction, as the PWA install funnel is short and doesn’t require clicking out of the browser, and in Play with only one listing users don’t need to choose between offerings</p>
                                <p><strong>Cons:</strong></p>
                                <p><span role="img" aria-label="cross">❌</span> Moving PWA users to the native application may be challenging when there is little differentiation between products</p>
                                <p><span role="img" aria-label="cross">❌</span> Users on mid-range and low-end devices discovering via Play may not install due to storage anxiety or device compatibility</p>
                                <p><span role="img" aria-label="cross">❌</span> Requires maintaining both PWA and native applications when there is little difference in experience. Cost could be reduced by going all-in on web (strategy 1)</p>
                            </div>
                        </div>
                        <ShareIt chapter="2" />
                        <ButtonsChapters next="chapter-3" previous="chapter-1" />
                    </div>
                </div>
            </div>

        </Layout>
    )
};
