import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 40 ? 0 : calculated > 65 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-11" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="11" />
                        <div className="entry">
                            <h2>Resources &amp; Tools</h2>
                            <h3>Resources</h3>
                            <p><a href="https://web.dev/progressive-web-apps/">web.dev/progressive-web-apps/</a>&nbsp;- In this collection, you'll learn what makes a Progressive Web App special, how they can affect your business, and how to build them.</p>
                            <p><a href="https://www.chromium.org/teams/web-capabilities-fugu">Fugu Web Capabilities Project</a>&nbsp;- Fugu is a cross-org program to bring powerful new capabilities to the web platform. It manages incoming requests from partners, determines demand, prioritizes them, finds a champion, tracks the development, and helps organize the release and documentation for the capability.</p>
                            <p><a href="https://codelabs.developers.google.com/dev-pwa-training/">Code lab for developing Progressive Web Apps</a></p>
                            <p><a href="https://developers.google.com/web/fundamentals/integration/webapks">WebAPKs on Android</a>&nbsp;- Chrome automatically generates and installs a special APK when your Progressive Web App is Installed</p>
                            <p><a href="https://web.dev/codelab-apple-touch-icon/">Code lab for adding an Apple Touch Icon</a></p>
                            <p><a href="https://developers.google.com/web/updates/2019/02/using-twa">Using Trusted Web Activities</a>&nbsp;- Trusted Web Activities are a new way to integrate your web-app content such as your PWA with your Android app</p>
                            <p><a href="https://codelabs.developers.google.com/codelabs/getting-started-with-twas/index.html%230">Code lab for Trusted Web Activities</a></p>
                            <p><a href="https://developers.google.com/web/updates/2017/11/overscroll-behavior">Take control of your scroll: customizing pull-to-refresh and overflow effects</a>&nbsp;- The CSS overscroll-behavior property allows developers to override the browser's default overflow scroll behavior when reaching the top/bottom of content.</p>
                            <p><a href="https://web.dev/web-share/">Share like a native app with the Web Share API</a>&nbsp;- The Web Share API makes it possible for web apps to share links, text, and files to other apps installed on the device in the same way as native apps.</p>
                            <p><a href="https://developers.google.com/web/tools/workbox/guides/enable-offline-analytics">Enable offline analytics</a>&nbsp;- Offline analytics is a module that will use background sync to ensure that requests to Google Analytics are made regardless of the current network condition. This is especially useful if you've built an app that works offline.</p>
                            <p><a href="https://serviceworke.rs/">Mozilla ServiceWorker Cookbook</a>&nbsp;- The Service Worker Cookbook is a collection of working, practical examples of using service workers in modern websites.</p>
                            <h3>Tools</h3>
                            <p><a href="https://developers.google.com/web/tools/lighthouse/">Lighthouse</a>&nbsp;- an open-source, automated tool for improving the quality of web pages. You can run it against any web page, public or requiring authentication. It has audits for performance, accessibility, progressive web apps, and more.</p>
                            <p><a href="https://aka.ms/pwa-builder">PWA Builder</a>&nbsp; - &nbsp;the OSS project helping you to create Progressive Web Apps. Want to package your PWA for the Google Play Store? It takes less than a minute to get a built and signed APK that is ready to go!</p>
                            <p><a href="https://github.com/GoogleChromeLabs/llama-pack">Llama Pack</a>&nbsp;- Llama Pack <span role="img" aria-label="cammel">🦙</span> is a Command Line Interface (CLI) that helps developers to create a Project for an Android application that launches an existing Progressive Web App (PWAs) using a Trusted Web Activity.</p>
                            <p><a href="https://developers.google.com/web/tools/workbox/">Google Workbox</a>&nbsp; - Workbox is a set of libraries and Node modules that make it easy to cache assets and take full advantage of features used to build Progressive Web Apps.</p>
                            <p><a href="https://developers.google.com/web/tools/chrome-user-experience-report">Chrome User Experience Report</a>&nbsp;- The Chrome User Experience Report provides user experience metrics for how real-world Chrome users experience popular destinations on the web</p>
                            <p><a href="https://developers.google.com/web/tools/chrome-devtools">Chrome DevTools</a>&nbsp;- a set of web developer tools built directly into the Google Chrome browser. DevTools can help you edit pages on-the-fly and diagnose problems quickly, which ultimately helps you build better websites, faster.</p>
                            <p><a href="https://www.thinkwithgoogle.com/feature/testmysite/">Test My Site</a>&nbsp;- See how your mobile site speed ranks compared to other top brands and learn how you can provide a faster, more frictionless mobile experience.</p>
                        </div>
                        <ShareIt chapter="11" />
                        <ButtonsChapters previous="chapter-10" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
