import React from 'react';
import Layout from '../component/layout';
import BreadCrump from '../component/breadCrumb';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 40  ? 0 : calculated > 65 ? 100 : calculated;
    return (
      <Layout>
    <div className="block-content" id="chapter-11"  ref={ref}>
        <div id="progress-bar" style={{width : finalPercentage+ "%"}}></div>
        <div className="content">
        <div className="inner">
            <BreadCrump/>
            <div className="entry">
                <h2>PAGE NOT FOUND</h2>
            </div>
        </div>
      </div>
    </div>
  </Layout>
  )
};
