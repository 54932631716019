import React from 'react';
import { Link } from "react-router-dom";

export const chapters = {
    '0': {
        link: '/chapter-0',
        title: 'Foreword'
    },
    '1': {
        link: "/chapter-1",
        title: "1. Installable Progressive Web Apps"
    },
    '2': {
        link: "/chapter-2",
        title: "2. Product Strategies for Progressive Web Apps"
    },
    '3': {
        link: "/chapter-3",
        title: "3. Requirements for Installability"
    },
    '4': {
        link: "/chapter-4",
        title: "4. Promoting Progressive Web App installation"
    },
    '5': {
        link: "/chapter-5",
        title: "5. Designing Installed Experiences"
    },
    '6': {
        link: "/chapter-6",
        title: "6. Designing Reliable Experiences"
    },
    '7': {
        link: "/chapter-7",
        title: "7. Designing Meaningful Notification Experiences"
    },
    '8': {
        link: "/chapter-8",
        title: "8. Measuring Impact"
    },
    '9': {
        link: "/chapter-9",
        title: "9. Case Studies"
    },
    '10': {
        link: "/chapter-10",
        title: "Closing"
    },
    '11': {
        link: "/chapter-11",
        title: "Resources & Tools"
    }
};

export default () => (
    <ol className="list-index">
        {Object.keys(chapters).map(key =>{
            return (
                <li key={key}>
                    <Link to={chapters[key]['link']}>{chapters[key]['title']}</Link>
                </li>
            );
        })}
    </ol>
);
