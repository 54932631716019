import React from 'react';
import { chapters } from './listIndex';

function handleAndroidShare(url, title, text) {
    return (e) => {
        e.preventDefault();
        navigator.share({
            title: title,
            text: text || title,
            url: url,
        });
    }
}

function Share({ url, title, text, children }) {
    if (navigator.share) {
        return (
            <button className="bt-share" onClick={handleAndroidShare(url, title, text)}>
                <svg height="22" width="22" viewBox="0 0 96 96"><path d="M72 56c-4.813 0-9.12 2.137-12.054 5.501L39.643 51.35c.23-1.081.357-2.201.357-3.35s-.127-2.269-.357-3.349l20.303-10.152C62.879 37.864 67.187 40 72 40c8.836 0 16-7.164 16-16S80.836 8 72 8s-16 7.164-16 16c0 1.149.127 2.269.357 3.349L36.054 37.501C33.121 34.136 28.814 32 24 32c-8.836 0-16 7.164-16 16s7.164 16 16 16c4.814 0 9.12-2.137 12.054-5.501l20.304 10.152C56.127 69.731 56 70.851 56 72c0 8.836 7.164 16 16 16s16-7.164 16-16-7.164-16-16-16zm0-40a8 8 0 110 16 8 8 0 010-16zM24 56a8 8 0 110-16 8 8 0 010 16zm48 24a8 8 0 110-16 8 8 0 010 16z"/></svg>
            </button>
        );
    }


    return (
        <div className="share-it">
            {children}

            <ul className="share">
                <li>
                    <a aria-label="Facebook" rel="noopener noreferrer" target="_blank" href={"https://www.facebook.com/sharer.php?u=" + url}>
                        <svg width="16" viewBox="0 0 100 100"><path d="M94.466 0c1.519 0 2.821.542 3.906 1.627C99.458 2.713 100 4.015 100 5.534v88.933c0 1.519-.542 2.821-1.627 3.906-1.086 1.085-2.388 1.627-3.907 1.627H69.01V61.263h12.956l1.953-15.104H69.01v-9.635c0-2.431.51-4.253 1.53-5.469s3.005-1.823 5.957-1.823l7.942-.065V15.69c-2.734-.391-6.597-.586-11.588-.586-5.903 0-10.623 1.736-14.16 5.208s-5.306 8.377-5.306 14.713v11.133h-13.02v15.104h13.021V100H5.534c-1.519 0-2.821-.542-3.906-1.627C.542 97.287 0 95.985 0 94.466V5.534c0-1.519.542-2.821 1.627-3.906C2.713.542 4.015 0 5.534 0h88.932z"></path></svg>
                    </a>
                </li>
                <li>
                    <a aria-label="Linkedin" rel="noopener noreferrer" target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + url}>
                        <svg width="16" viewBox="0 0 430.1 430.1"><path d="M398.4 0H31.8C14.2 0 0 13.8 0 30.8v368.5c0 17 14.2 30.8 31.8 30.8h366.6c17.5 0 31.8-13.8 31.8-30.8V30.8c-.1-17-14.3-30.8-31.8-30.8zm-268 360h-65V165.8h65V360zM97.9 139.3h-.4c-21.8 0-35.9-14.9-35.9-33.6 0-19 14.5-33.5 36.8-33.5 22.2 0 35.9 14.5 36.3 33.5 0 18.7-14.1 33.6-36.8 33.6zM364.7 360h-65V256.1c0-26.1-9.4-43.9-32.9-43.9-18 0-28.6 12-33.3 23.6-1.7 4.1-2.2 9.9-2.2 15.7V360h-65s.9-176 0-194.2h65v27.5c8.6-13.2 24-32.1 58.5-32.1 42.8 0 74.8 27.7 74.8 87.4l.1 111.4zM230.9 194c.1-.2.3-.4.4-.6v.6h-.4z"></path></svg>
                    </a>
                </li>
                <li>
                    <a aria-label="Twitter" rel="noopener noreferrer" target="_blank" href={"https://twitter.com/intent/tweet?text=" + title + " - PWA The Superpowers of the Web and Native Apps Combined " + url + "&hashtags=AwwwardsBook,ebook,pwa"}>
                        <svg width="16" viewBox="0 0 29.52 24"><path d="M9.28 24c11.145 0 17.24-9.236 17.24-17.24 0-.257 0-.517-.02-.786 1.187-.86 2.205-1.918 3.02-3.14-1.103.49-2.28.814-3.484.953 1.27-.758 2.217-1.944 2.67-3.352-1.187.703-2.49 1.205-3.844 1.472-2.3-2.437-6.134-2.557-8.568-.26-1.575 1.483-2.243 3.687-1.75 5.792C9.67 7.197 5.13 4.89 2.055 1.11.443 3.88 1.268 7.42 3.928 9.2c-.963-.03-1.91-.29-2.753-.76v.07c0 2.882 2.038 5.367 4.864 5.94-.893.242-1.824.277-2.733.103.797 2.465 3.065 4.16 5.66 4.204-2.15 1.688-4.798 2.604-7.523 2.604-.48 0-.963-.024-1.443-.08C2.76 23.054 5.98 24 9.28 24"></path></svg>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default ({chapter}) => {
    const baseURL = 'https://pwa-book.awwwards.com';

    const title = chapter ? chapters[chapter].title : "Download the brand new ebook! “Progressive Web Apps: The Superpowers of the Web and Native Apps Combined” by Google @ChromiumDev and @Awwwards";
    const url = chapter ? baseURL + chapters[chapter].link : baseURL;

    return (
        <Share url={url} title={title}>
            <span>Share</span>
        </Share>
    );

}
