import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 40 ? 0 : calculated > 60 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-3" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="0" />
                        <div className="entry">
                            <h2>Foreword</h2>
                            <div className="by">By PJ McLachlan</div>
                            <p>The web is literally everywhere: on watches, smartphones, tablets, desktops, cars, TVs and digital signboards. Thanks to browsers, the software to display web content on all of these devices and screen sizes can be exactly the same.</p>
                            <p>While browsers remain central for users, every device now has its own app store, which has changed user behavior. On Android, Chrome OS, Mac or Windows, users have learned that the device&rsquo;s store is where they can <em>install</em> software that&rsquo;s made for their device.&nbsp;</p>
                            <p>The essence of <em>installed</em> software is a commitment that the software will integrate with the device in ways that the user expects. The integration of installed software makes it easy to launch software again, for example with an icon on the phone&rsquo;s home screen or in the start menu. It also means the user can toggle between software with the device&rsquo;s built-in activity switcher such as &lsquo;alt-tab&rsquo; on Windows or the app switcher on a phone.&nbsp;&nbsp;</p>
                            <p>Progressive Web Apps (PWAs) combine the reach of the web with the device integrations of installed software. PWAs can be discovered and installed on any device with a modern web browser, on both mobile and desktop, and also in stores such as Google Play. No matter how it was discovered, if the software offers the user repeatable utility and a great experience, the user can choose to install it and integrate the experience into their device - just like any other app.</p>
                            <p>Examples of Progressive Web Apps include Google&rsquo;s YouTube Music and Google Earth, and industry leading experiences including Twitter, Spotify, OYO and Etam.</p>
                            <p>For more background on building on the fundamentals of Progressive Web Apps, check out <a href="https://www.awwwards.com/PWA-ebook/">Volume 1 in this ebook series.</a></p>
                            <p>This ebook, Volume 2 in the series, will cover: the capabilities of Progressive Web Apps, product strategies for deploying PWAs, promotional patterns for driving installs of PWAs, UX best practices for designing installed experiences that work reliably offline and have meaningful notifications, guidelines on how to implement analytics to measure business impact and case studies from businesses that have built great PWAs.</p>
                        </div>
                        <ButtonsChapters next="chapter-1" previous="" />
                    </div>
                </div>
            </div>

        </Layout>
    )
};
