import React from 'react';
import Header from './header';
import Footer from './footer';
import ScrollToTop from './scrollToTop';


export default function (props) {
  // eslint-disable-next-line
  const { children } = props;
  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className='block-content'>
        {children}
      </div>
      <Footer />
      <div className="box-abs-right fixed">
        <div className="box-brain">
          <strong>Brain Food</strong>
          <span>Vol 6</span>
        </div>
      </div>
    </div>
  );
}
