import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 50 ? 0 : calculated > 50 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-10" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div class="content">
                    <div class="inner">
                        <BreadCrumb chapter="10" />
                        <div className="entry">
                            <h2>Closing</h2>
                            <p>So there you have it: how Progressive Web Apps combine the superpowers of the web and native applications. It should now be clear what PWAs are capable of, how to deploy them either alongside native applications, how to design an installed experience that feels like it belongs on the device, how to measure the impact, and real life use cases from businesses that are already seeing success.</p>
                            <p>When thinking about PWAs, TWAs, Native Apps, or any digital product, always keep in mind: how might you reach as many people as possible, with the best experience possible, in the most cost effective way.</p>
                            <p>Thanks for reading.</p>
                        </div>
                        <ShareIt chapter="10" />
                        <ButtonsChapters next="chapter-11" previous="chapter-9" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
