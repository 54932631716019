 // src/components/Notifier/index.js
 import React, { Component } from "react";
 import classnames from 'classnames';

 class Notifier extends Component {
   render() {
     const notifyclass = classnames('notify', {
       danger: this.props.offline
     });
     const message = this.props.offline ? `Pwa Book is offline!.`: false;
     if (message){ 
        return (
            <div className={notifyclass}>
                <p>
                    <em>{message}</em>
                </p>
            </div>
        );
        }else{
            return null
        }
    }
 }

 export default Notifier;