import React, { Component } from 'react';
import Sidebar from '../component/sidebar';
import InstallMobile from '../component/installMobile';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSidebar: props.isOpenSidebar,
      shoModal: false,
      showInstallButton: false
    };

    this.deferredPrompt = null;
  }

  componentDidMount () {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (iOS){
      this.showInstallButton();
    }
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;

      this.showInstallButton();
    });

    window.addEventListener('appinstalled', (evt) => {
      this.hideInstallButton();
    });
  }

  openSidebar(){
     this.setState({isOpenSidebar: !this.state.isOpenSidebar});
  }

  showModal(){
    this.setState({showModal: !this.state.showModal});
  }

  showInstallButton () {
    this.setState({showInstallButton: true});
  }

  hideInstallButton () {
    this.setState({showInstallButton: false});
  }

  showInstall () {
    this.hideInstallButton();
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      this.deferredPrompt = null;
    })
  }


  installButton () {
    if (!this.state.showInstallButton) {
      return null;
    }
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS){
      return <div className="bt-download js-download"  onClick={this.showModal.bind(this)} id="show-modal-button">INSTALL <em className="stick">PWA</em> EBOOK</div>;
    }else{
      return <div className="bt-download js-download"  onClick={this.showInstall.bind(this)} id="show-modal-button">INSTALL <em className="stick">PWA</em> EBOOK</div>;
    }
  }


  render() {
    const { isOpenSidebar } = this.state;
    return (
      <div>
      <header id="header">
          <div className="box-left">
              <div className="logo">
                <a href="/" aria-label="Home">
                  <svg width="40" viewBox="0 0 55 31"><polygon points="43,0 33.3,30.9 26.7,30.9 21.5,10.9 16.3,31 9.7,31 0,0.1 7.6,0.1 13,21.3 18.1,0.1 25,0.1 30.1,21.3 35.5,0.1 "></polygon><path d="M50,21c2.9,0,5,2.1,5,5s-2.1,5-5,5s-5-2.1-5-5S47.2,21,50,21z"></path></svg>
                  <span className="label">HOME</span>
                </a>
              </div>
              <div className="title">The Superpowers of the Web and native Apps Combined</div>
          </div>
          <div className="box-right">
            {this.installButton()}
            <div onClick={this.openSidebar.bind(this)} className={`bt-mobile js-menu ${isOpenSidebar ? "open" : ""}`} id="openMenu">
              <div className="lines">
                <div className="top_line"></div>
                <div className="middle_line"></div>
                <div className="bottom_line"></div>
              </div>
              <span className="label">MENU</span>
            </div>
          </div>
      </header>
      <InstallMobile show={this.state.showModal} onClickClose={this.showModal.bind(this)} />
      <Sidebar isOpen={isOpenSidebar} onClose={this.openSidebar.bind(this)}/>
      <div className="box-fixed-msg js-new-version">
          <div className="inner">
              <p>There is a new version of the App.</p>
              <div className="js-accept button style1">Accept</div>
          </div>
      </div>
      </div>
    );
  }
}
