import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-6" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div class="content">
                    <div class="inner">
                        <BreadCrumb chapter="6" />
                        <div className="entry">
                            <h2>Designing Reliable Experiences</h2>
                            <div className="by">By Anna Potanina</div>
                            <p>Providing an offline experience in a Progressive Web App means a user experience that is consistent with what they expect from native applications, which often have some offline functionality: at least the UI is available and reasonable error messages are displayed if there are network connectivity issues.</p>
                            <p>When defining the offline experience consider applying design thinking methods and brainstorm what offline functionality can benefit the user. Here are some “How-Might-We” examples for brainstorm inspiration:</p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image47.webp" type="image/webp" />
                                          <source srcSet="images/content/image47.png" type="image/png" /> 
                                          <img src="images/content/image47.webp" alt="pwa book" />
                                        </picture>
                                    </figure>
                                </div>
                            </div>
                            <h3>User First Principles for Offline</h3>
                            <p>“Connectivity is not On or Off. It is On and Off. ”</p>
                            <p>When designing reliable experiences, think about connectivity as something that is constantly changing its state. Especially when on mobile devices, users can be traveling underground, entering elevators or just simply experiencing bad connectivity issues. Providing an offline experience opens new ways to engage with users and keep them using your PWA in all connection states.</p>
                            <p>When defining an offline experience, consider the following questions:</p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image41.webp" type="image/webp" />
                                          <source srcSet="images/content/image41.png" type="image/png" /> 
                                          <img src="images/content/image41.webp" alt="pwa book" />
                                        </picture>
                                    </figure>
                                </div>
                            </div>
                            <h3>Informing about the change of state</h3>
                            <p>On a very basic level, at least inform users about the change of state. Three elements that can be used to describe this change are:</p>
                            <ol>
                                <li><strong>Copy</strong> -&nbsp; words to inform users about the change of state</li>
                                <li><strong>Icons</strong> - visuals that help illustrate the current status</li>
                                <li><strong>Colors</strong> - to highlight the content available offline</li>
                            </ol>
                            <p>The best practice is to use at least two of the above, to avoid user confusion. When using colors, greying out certain areas of the website can be interpreted not only as an offline state, but also as if some of the items are out of stock. So, to make things clear, also use copy to inform the user.</p>
                            <div class="grid">
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image21.webp" type="image/webp" />
                                          <source srcSet="images/content/image21.png" type="image/png" /> 
                                          <img src="images/content/image21.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example 1: Copy and colors to illustrate the change of state</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image31.webp" type="image/webp" />
                                          <source srcSet="images/content/image31.png" type="image/png" /> 
                                          <img src="images/content/image31.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example 2: Copy, colors and an icon to illustrate the change of state</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image20.webp" type="image/webp" />
                                          <source srcSet="images/content/image20.png" type="image/png" /> 
                                          <img src="images/content/image20.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example 3: Copy and visuals to illustrate the change of state</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>With copy there are a few things to consider:</p>
                            <ul>
                                <li>Keep things simple. Remember, users are often not technically minded&nbsp;</li>
                                <li>Avoid blaming the user or systems for a bad connection. Instead, simply confirm the state and inform users of the next actions</li>
                                <li>If the brand strategy includes informal language, use it with care, as it can undermine the credibility of the PWA in such a sensitive and vulnerable moment&nbsp;</li>
                            </ul>
                            <div class="box-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p><span role="img" aria-label="cross">❌</span> <strong>Don’t</strong></p>
                                            </td>
                                            <td>
                                                <p>✔ <strong>Do</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>“You are not</strong> connected to the Internet. Please <strong>reconnect</strong> and <strong>retry</strong>.”</p>
                                                <p>“Looks like <strong>our</strong> <strong>server is down</strong>.”</p>
                                                <p>“Sorry, something <strong>went wrong on our end</strong>.”</p>
                                                <p>“Looks like <strong>something was unplugged</strong>. Thanks for your patience while <strong>we are getting this fixed</strong>.”</p>
                                            </td>
                                            <td>
                                                <p>“You are currently <strong>offline</strong>.”</p>
                                                <p><strong>“We will</strong> try to reconnect you in 30 seconds.”</p>
                                                <p><strong>“The page will be loaded</strong> once you are back online.”</p>
                                                <p><strong>“Press here</strong> to refresh the page.”</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><span role="img" aria-label="cross">❌</span> Don’t blame the user.</p>
                                                <p><span role="img" aria-label="cross">❌</span> Don’t ask the user to fix things.</p>
                                                <p><span role="img" aria-label="cross">❌</span> Don’t blame your own systems as it affects service credibility.</p>
                                                <p><span role="img" aria-label="cross">❌</span> Use informal language with care.</p>
                                            </td>
                                            <td>
                                                <p>✔ Confirm the state as-is with no blame.</p>
                                                <p>✔ Reassure the user that they don’t need to “fix” anything</p>
                                                <p>✔ Explain what is going to happen when the connection is back (whether the user needs to refresh or the page will reload automatically).</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>A popular UI component to inform the user about the change of state is a<strong> pop up toast</strong> (or a <a href="https://material.io/components/snackbars/">snackbar</a>). It can appear in any place in the UI, but check on every page that it doesn’t block navigation elements. Besides the copy, the toast can also carry an icon and a call-to-action button for example: refresh the page or dismiss the notification.</p>
                            <div class="grid">
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image48.webp" type="image/webp" />
                                          <source srcSet="images/content/image48.png" type="image/png" /> 
                                          <img src="images/content/image48.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Toast on the bottom of the page with a REFRESH call-to-action</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image6.webp" type="image/webp" />
                                          <source srcSet="images/content/image6.png" type="image/png" /> 
                                          <img src="images/content/image6.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fade the toast out or let users dismiss it if there is another signal of the ongoing offline state active e.g. greyed out color</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image39.webp" type="image/webp" />
                                          <source srcSet="images/content/image39.png" type="image/png" /> 
                                          <img src="images/content/image39.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Toast placed above the navigation bar</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <h3>Providing an offline experience</h3>
                            <p>In addition to simply notifying the user they are offline, many types of experiences can be provided offline.</p>
                            <p><strong>Fallback experiences</strong></p>
                            <p>These experiences provide an alternative experience to the user when the connection is interrupted. Examples are:</p>
                            <ul>
                                <li>Show a <strong>static offline</strong> page. This could contain alternative ways to reach the business, such as a store locator, contact info to get in touch with the call center, a contact form that is submitted when the user is back online</li>
                                <li><strong>Be useful </strong>and show relevant information from the user’s account. A travel company could make tickets or boarding passes available. A retailer could show saved items or items in the basket</li>
                                <li><strong>Entertain</strong> users with a game or puzzle or fun facts, and occupy their time while they wait for the connection to return</li>
                            </ul>
                            <div class="grid">
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image42.webp" type="image/webp" />
                                          <source srcSet="images/content/image42.png" type="image/png" /> 
                                          <img src="images/content/image42.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: showing a simple branded placeholder page</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image21.webp" type="image/webp" />
                                          <source srcSet="images/content/image21.png" type="image/png" /> 
                                          <img src="images/content/image21.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: Inviting to browse some pre-cached content</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image32.webp" type="image/webp" />
                                          <source srcSet="images/content/image32.png" type="image/png" /> 
                                          <img src="images/content/image32.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: show contact info</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image49.webp" type="image/webp" />
                                          <source srcSet="images/content/image49.png" type="image/png" /> 
                                          <img src="images/content/image49.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: show store locator</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image3.webp" type="image/webp" />
                                          <source srcSet="images/content/image3.png" type="image/png" /> 
                                          <img src="images/content/image3.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: be useful</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image50.webp" type="image/webp" />
                                          <source srcSet="images/content/image50.png" type="image/png" /> 
                                          <img src="images/content/image50.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fallback experience: entertain</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Continue browsing offline</strong></p>
                            <p>Caching can be used to allow users to continue browsing when they are offline.&nbsp;</p>
                            <ul>
                                <li>Use <strong>pre-caching </strong>to let the user continue browsing important areas of the site such as top categories or items on sale</li>
                                <li>Use <strong>run-time caching </strong>to make any previously viewed page available</li>
                            </ul>
                            <p>Be mindful to make users aware of what content is available to browse, provide appropriate navigation and information on what actions are possible.</p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image15.webp" type="image/webp" />
                                          <source srcSet="images/content/image15.png" type="image/png" /> 
                                          <img src="images/content/image15.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example: keeping the user within the context of the product listing page if the next page is not cached. Offering alternative call-to-actions. e.g. “Share” or “Save for later”.</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image45.webp" type="image/webp" />
                                          <source srcSet="images/content/image45.png" type="image/png" /> 
                                          <img src="images/content/image45.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Go back through pages available with Back button</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Complete tasks offline</strong></p>
                            <p>More advanced offline experiences can allow users to complete their current task, such as adding items to the cart, saving to a wishlist, completing a form or even complete all the details in checkout, right up to payment. It is important to reassure the user they can continue their task and their actions won’t be wasted, despite being offline.</p>
                            <p>When the connection is back, <strong>inform the user about the change of state</strong> and update the content if there are any changes required. If the user can be kept within their current context, reload the page automatically. If the context cannot be kept, give control to the user to refresh the page with a button. </p>
                            <div class="grid">
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image34.webp" type="image/webp" />
                                          <source srcSet="images/content/image34.png" type="image/png" /> 
                                          <img src="images/content/image34.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Fill out the form</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image18.webp" type="image/webp" />
                                          <source srcSet="images/content/image18.png" type="image/png" /> 
                                          <img src="images/content/image18.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Add to cart</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image29.webp" type="image/webp" />
                                          <source srcSet="images/content/image29.png" type="image/png" /> 
                                          <img src="images/content/image29.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Save to wishlist</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <ShareIt chapter="6" />
                        <ButtonsChapters next="chapter-7" previous="chapter-5" />
                    </div>
                </div>

            </div>
        </Layout>
    )
};
