import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-9" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div class="content">
                    <div class="inner">
                        <BreadCrumb chapter="9" />
                        <div className="entry">
                            <h2>Case Studies</h2>
                            <h3>Weekendesk turns their mobile site into a Progressive Web App and gets 2.5x more conversions</h3>
                            <p>Weekendesk is #1 for weekends and short thematic stays (well-being, gastronomy, relaxation ...) and has created an ultra-fluid experience by reconciling their website and native app.&nbsp;</p>
                            <p>With more than 60% of web sessions coming from mobile and an aging Android app, Weekendesk has a strategic interest in offering the best mobile experience to its customers, while unifying its web and app developments.</p>
                            <p>Weekendesk has gradually added modern web functionality to its mobile site, to transforming it into a true Progressive Web App, which makes it as smooth as a native app.</p>
                            <p>Thanks to the Service Worker the site becomes faster and available in offline mode, and the proposal to install the PWA brings more comfort to users who are already engaged.</p>
                            <p>After transforming its site into PWA, Weekendesk took advantage of Trusted Web Activities, allowing them to publish the PWA directly in the Play Store.</p>
                            <div class="grid">
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image11.webp" type="image/webp" />
                                          <source srcSet="images/content/image11.png" type="image/png" /> 
                                          <img src="images/content/image11.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Thanks to the Service Worker, the website is 15-30% faster and transitions between pages are smoother</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image5.webp" type="image/webp" />
                                          <source srcSet="images/content/image5.png" type="image/png" /> 
                                          <img src="images/content/image5.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>When losing network connection, the wishlist can be accessed in offline mode</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image9.webp" type="image/webp" />
                                          <source srcSet="images/content/image9.png" type="image/png" /> 
                                          <img src="images/content/image9.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>On 2nd page visited, Weekendesk promotes PWA install</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image17.webp" type="image/webp" />
                                          <source srcSet="images/content/image17.png" type="image/png" /> 
                                          <img src="images/content/image17.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>The  PWA can be installed from the Play Store like any other native app</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Quote:</strong></p>
                            <p>“Our users expect a fast and intuitive experience regardless of the channel. Our successful bet was to modernize our website in Progressive Web App then to publish it also in the Play Store ”</p>
                            <p>— Maxime Biloé, Lead Front Developer, Weekendesk</p>
                            <p><strong>Results:</strong></p>
                            <p><strong>For the users: </strong>The same ultra-fluid experience accessible everywhere: on the web and in app!</p>
                            <p><strong>For the product team:</strong> Faster deployment of new features, and lower costs with the maintenance of a single code.</p>
                            <p><strong>For the business: </strong>2.5x conversions when users come from the installed PWA, a site up to 30% faster, more engagement in page views (+ 108%) and time spent on the site ( + 85%).</p>
                            <p>Read the<a href="https://www.thinkwithgoogle.com/_qs/documents/8971/Weekendesk_PWA_-_EXTERNAL_CASE_STUDY.pdf"> case study</a> (French)</p>
                            <h3>Etam, a pioneer of the next generation e-commerce experience, leverage Progressive Web Apps to improve the mobile experience.</h3>
                            <p>Etam, a specialist in women's lingerie, takes advantage of a Progressive Web App to improve the user experience on mobile.&nbsp;</p>
                            <p>With more than 70% of sessions coming from smartphones, Etam understands the importance of optimizing the online customer journey, especially on mobile. Etam’s native application was aging, so the team chose not to overhaul it, but to instead focus on improving the web experience.</p>
                            <p>The developers first focused on speed by optimizing the code. They then implemented an architecture that remembers the static content of the page, including the customer area. This notably allows the user to have their loyalty card and their favorites list, even if they are offline.</p>
                            <div class="grid">
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image24.webp" type="image/webp" />
                                          <source srcSet="images/content/image24.png" type="image/png" /> 
                                          <img src="images/content/image24.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Offline: access loyalty card&nbsp;</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image7.webp" type="image/webp" />
                                          <source srcSet="images/content/image7.png" type="image/png" /> 
                                          <img src="images/content/image7.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Offline: access favorites list</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image36.webp" type="image/webp" />
                                          <source srcSet="images/content/image36.png" type="image/png" /> 
                                          <img src="images/content/image36.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Install: Custom UI prompt, first left tile. Text translates to: “<strong>Be the first to know about our latest news</strong>. Our application is fast, light and works in offline mode.”</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image13.webp" type="image/webp" />
                                          <source srcSet="images/content/image13.png" type="image/png" /> 
                                          <img src="images/content/image13.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Installed: users who launch the app from the homescreen get an immersive browsing experience. The layout is also similar to Native App UX with the menu bar at the bottom of the screen</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>Etam makes it easy for users to sign in, using the WebAuth protocol to facilitate login with fingerprint. Etam also makes it easy to purchase with just 3 clicks, using Google Pay.</p>
                            <div class="grid">
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image25.webp" type="image/webp" />
                                          <source srcSet="images/content/image25.png" type="image/png" /> 
                                          <img src="images/content/image25.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>WebAuth protocol facilitates login with fingerprint on the web</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image12.webp" type="image/webp" />
                                          <source srcSet="images/content/image12.png" type="image/png" /> 
                                          <img src="images/content/image12.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Payment in 3 clicks thanks to Google Pay</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>Etam continues to innovate by publishing its PWA to the Google Play Store, using Trusted Web Activities (TWA). Etam is therefore accessible on the Google Play Store for users looking for the brand, and with just one code to manage, one team is enough to maintain a seamless experience that can be quickly updated.</p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image44.webp" type="image/webp" />
                                          <source srcSet="images/content/image44.png" type="image/png" /> 
                                          <img src="images/content/image44.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>PWA in the Google Play Store using Trusted Web Activites. Only one code to manage to be present on the web and in the Play Store</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Quote:</strong></p>
                            <p>“The Progressive Web Apps bet is successful! We have saved considerable development time. This allowed us to innovate more with Trusted Web Activities” — Edouard Bataille, Creative Technologist, Etam</p>
                            <p><strong>Results:</strong></p>
                            <ul>
                                <li>The work on speed made it possible to obtain a revisit loading time of less than a second</li>
                                <li>Users who opened installed the PWA and opened via homescreen had an average basket +18% and a conversion rate +30% higher than other mobile users&nbsp;</li>
                                <li>The improvement of the mobile site also had a cross-device effect because the conversion rate of the desktop also increased</li>
                            </ul>
                            <p>Read the<a href="https://www.thinkwithgoogle.com/_qs/documents/9094/Etam_-_PWA_case_study_EXTERNALLY_SAFE.pdf"> case study</a> (French)</p>
                        </div>
                        <ShareIt chapter="9" />
                        <ButtonsChapters next="chapter-10" previous="chapter-8" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
