import React from 'react';
import ListIndex from '../component/listIndex';

export default class Sidebar extends React.Component {

  render() {
    const { isOpen, onClose } = this.props;

    return (
        <div id="sidebar" className={isOpen ? "open" : ""}>
            <div className="overlay" onClick={onClose}></div>
            <div className="content">
                <div className="inner">
                    <div className="breadcrumb style2">
                        <div className="box-left">
                            <strong><a href="https://www.awwwards.com/books/">Ebooks</a></strong> / <a href="/">PWA Book 2</a>
                        </div>
                    </div>
                    <ListIndex />
                </div>
            </div>
        </div>
    );
  }
}
