import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 40 ? 0 : calculated > 70 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-3" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="3" />
                        <div className="entry">
                            <h2>Requirements for Installability</h2>
                            <div className="by">By Martin Schierle</div>
                            <h3>Installable Progressive Web App requirements</h3>
                            <p>The <a href="https://web.dev/install-criteria/">basic requirements</a> to make a Progressive Web App installable are: serving over HTTPS, a service worker, and a web app manifest.&nbsp;</p>
                            <p><strong>Served over HTTPS:</strong></p>
                            <p>It’s 2020. All sites should be using HTTPs whether they are PWAs or not!</p>
                            <p><strong>Service worker:</strong></p>
                            <p>Service workers are the technology that underpins all PWA functionality, and they can do many powerful things including caching and providing offline experiences. Though browser requirements vary, it is highly recommended to have at least a working service worker with a fetch handler.</p>
                            <p><strong>Web App Manifest:</strong></p>
                            <p>The Manifest file is a JSON file which informs how the PWA acts when it's installed. It needs to be linked from every page, so that the user can tap to install at any time. Almost all modern browsers have some level of support for it.</p>
                            <p>The manifest should include:</p>
                            <ul>
                                <li><strong>Short_name and name:</strong> The short_name is used on the user's home screen, launcher, or other places where space may be limited. Name is used in the app install prompt.</li>
                                <li><strong>Icons: </strong>&nbsp;used in places like the home screen, app launcher, app switcher, splash screen, etc. Chrome requires a 192x192px and a 512x512px icon and additional sizes, including 270x270 and 180x180 are recommended.&nbsp; Further sizes are optional for those that want pixel perfect icons</li>
                                <li><strong>Start_url: </strong>this is where the PWA should start when it is launched from the homescreen, and prevents the app from starting on whatever page the user was on when installed</li>
                                <li><strong>Display: </strong>It is recommended to use ‘standalone’ as the display mode. This will allow the PWA to open separately to the browser and give the look and feel of a native app</li>
                            </ul>
                            <p>There are other items that can be included in the manifest, which you can read <a href="https://web.dev/add-manifest/">here</a>. The chapter “<a href="/chapter-5">Designing Installed Experiences</a>” will cover some UX best practices for icons and naming.</p>
                            <p><strong>Apple Touch Link</strong></p>
                            <p><a href="https://web.dev/apple-touch-icon/">iOS is a little different.</a> When iOS Safari users install, the icon that appears is called the Apple touch icon. Include a &lt;link rel="apple-touch-icon" href="/example.webp"&gt; tag in the &lt;head&gt; of the page to specify what icon the PWA should use. If this is not included, iOS generates an icon by taking a screenshot of the page content. In other words, instructing iOS to download an icon results in a more polished user experience.</p>
                            <p>iOS icons should be: 180x180 pixels or 192x192 pixels, and the background of the icon should not be transparent. <a href="https://web.dev/codelab-apple-touch-icon/">This code lab</a> shows exactly how to do this.</p>
                            <p><strong>Audits</strong></p>
                            <p>Use <a href="https://developers.google.com/web/tools/lighthouse/">Lighthouse</a> to run audits and check what is missing to enable Installability.</p>
                            <h3>Publishing in Play with Trusted Web Activities</h3>
                            <p>Using Trusted Web Activities (TWAs), Progressive Web Apps can be integrated with an Android app and published in the Play Store. TWAs must meet the Lighthouse PWA installability criteria and load fast, with a Lighthouse Performance score of 80 or better. They must also comply with Play store <a href="https://play.google.com/about/developer-content-policy/">policy</a> including policies for <a href="https://play.google.com/about/monetization-ads/payments/">payments</a>, in-app purchases and other digital goods.</p>
                            <p>Learn more about how to publish a PWA in Play using TWA <a href="https://developers.google.com/web/updates/2019/02/using-twa">here</a>, and check out tools like <a href="https://github.com/GoogleChromeLabs/llama-pack">Llama pack</a> and <a href="https://aka.ms/pwa-builder">PWA builder</a> that help simplify this process.</p>
                        </div>
                        <ShareIt chapter="3" />
                        <ButtonsChapters next="chapter-4" previous="chapter-2" />
                    </div>
                </div>
            </div>

        </Layout>
    )
};
