import React from 'react';
import { Link } from "react-router-dom";

export default ({next, previous, isOpenSidebar}) => {

return (
    <div className="nav">
        {previous && 
            <Link className="text-link prev" to={previous}>Previous Chapter</Link>
        }
        <span onClick={()=>{
            let ele = document.getElementById('openMenu')
            ele.click()
        }} className="text-link index">Index</span>
        {next && 
            <Link className="text-link next" to={next}>Next Chapter</Link>
        }
    </div>
);

}
