import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-4" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div className="content">
                    <div className="inner">
                        <BreadCrumb chapter="4" />
                        <div className="entry">
                            <h2>Patterns for Promoting PWA Install from the Browser</h2>
                            <div className="by">By Anna Potanina</div>
                            <p>Promoting Progressive Web App installation should happen at the right moment in the user journey.&nbsp; The best practice is to use to customize visuals, copy and design to align with both the brand identity and customer needs. For an overview on which browsers support a fully customizable install prompt experience, take a look at <a href="https://caniuse.com/#feat=mdn-api_beforeinstallpromptevent">caniuse.com</a></p>
                            <h3>Browser Install on Chrome Android</h3>
                            <p>Chrome on Android allows fully customizable UI prompts. First, make sure to prevent the default experience, which triggers the mini info-bar.</p>
                            <p><strong>Preventing the default mini info-bar on Chrome</strong></p>
                            <p>The mini info-bar was only intended as a temporary shim. It’s not the best user experience, and since it’s generated by the browser, it has no way to know when it is the right moment to appear. It just shows up as soon as the site passes installability criteria and a user engagement measure.&nbsp;</p>
                            <p>The recommended best practice to stop the mini info-bar from showing, and instead use a customized prompt. <a href="https://developers.google.com/web/fundamentals/app-install-banners#preventing_the_mini-infobar_from_appearing">Here are the details on how to do that.</a></p>
                            <p><strong>Best practices for customized UI prompts:</strong></p>
                            <div className="grid">
                                <div className="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image37.webp" type="image/webp" />
                                          <source srcSet="images/content/image37.png" type="image/png" /> 
                                          <img src="images/content/image37.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example: Install prompts - looks and feels native to the UI, contains value proposition and some visuals, appears at the right time, can be re-discovered throughout the journey</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>✔ <strong>Appear when appropriate</strong>&nbsp;</p>
                            <p>The ground rule here is not to ask users to install the PWA on their first visit. Instead, consider prompting engaged users, for example: when they have viewed certain key content, or on their second visit.</p>
                            <p>Consider making the prompt discoverable throughout the site, however make sure not to interrupt the user and break the flow.&nbsp; It is better to prompt when the user is either in browsing mode and not deep in a specific task, or when they have just completed a certain action, such as added a product to favorites, completed a purchase, finished reading the page or finished the game.&nbsp;&nbsp;</p>
                            <p>It’s a good idea to also fix the Install prompt UI element in the side drawer or within the navigation header, so that it’s always available to the user, without being interruptive.</p>
                            <p>✔ <strong>Use a value proposition&nbsp;</strong></p>
                            <p>It is no secret that people need motivation to complete any task. Motivate users to install the PWA by explaining why they should do that and what they will get at the end.&nbsp;</p>
                            <p>The important thing about any value proposition is to talk about benefits that the user is going to get and not only the PWA features.&nbsp;</p>
                            <div className="box-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p><span role="img" aria-label="cross">❌</span> &nbsp; <strong>Don’t</strong></p>
                                            </td>
                                            <td>
                                                <p>✔ <strong>Do</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>“Don’t worry </strong>about the phone space! Our app won’t take up too much.”</p>
                                            </td>
                                            <td>
                                                <p>“Install our <strong>free</strong> web app on your homescreen to have quick access to <strong>your</strong> favorites”</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><span role="img" aria-label="cross">❌</span>  Avoid negative wording</p>
                                            </td>
                                            <td>
                                                <p>✔ Highlight user benefits</p>
                                                <p>✔ Customize the message depending on where the user is in the funnel</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>“</strong>Install<strong> our </strong>app which is <strong>fast, reliable</strong> and <strong>light</strong>.”</p>
                                            </td>
                                            <td>
                                                <p>“Welcome back! Get our web app to always have <strong>your </strong>bookings with <strong>you </strong>even when you are offline.”</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><span role="img" aria-label="cross">❌</span>  Don’t talk about only the app features - users are not very technical. Consider: what does it mean for the user?</p>
                                            </td>
                                            <td>
                                                <p>✔ Highlight user benefits</p>
                                                <p>✔ Customize the message depending on where the user is in the funnel</p>
                                                <p>✔ Talk about the user, not about the app</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><strong>✔ Use</strong><strong> visuals to illustrate action</strong></p>
                            <p>Mother nature has built into the brain the ability to recognize images instantly. Visuals (icons, pictures, illustrations) are a very important signal - they help illustrate the content and functionality so that the user's brain recognizes and understands faster.&nbsp;</p>
                            <p>Include icons such as a download icon or an install button to illustrate to the user the action they should take.</p>
                            <p>✔ <strong>Integrate with the UI</strong></p>
                            <p>Pop-ups have historically become really popular for promoting installs, however every pop-up is an intrusive experience which will likely get ignored or dismissed by users.</p>
                            <p>Less intrusive experiences lead to higher engagements. Prompts that are integrated within the UI as part of the site are more likely to be well received by a user.</p>
                            <div className="grid">
                                <div className="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image1.webp" type="image/webp" />
                                          <source srcSet="images/content/image1.png" type="image/png" /> 
                                          <img src="images/content/image1.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption><span role="img" aria-label="cross">❌</span>  Pop-ups and overlays usually get ignored and dismissed</figcaption>
                                    </figure>
                                </div>
                                <div className="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image14.webp" type="image/webp" />
                                          <source srcSet="images/content/image14.png" type="image/png" /> 
                                          <img src="images/content/image14.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>✔ Native look and feel of the prompt creates a less intrusive experience</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>Read more about promotional patterns and see more options of when the prompt can appear in the user journey and how it can look <a href="https://developers.google.com/web/fundamentals/app-install-banners/promoting-install-mobile#in-feed">here</a>.&nbsp;</p>
                            <blockquote>
                                <p>Reminder: don’t promote your PWA to users that already have the native app installed! This was mentioned earlier, but is important so it’s being mentioned again here!</p>
                                <p>Use the getInstalledRelatedApps API to promote PWA install only to users that don’t have the native app installed. See <a href="https://web.dev/get-installed-related-apps/" target="_blank" rel="noopener noreferrer">https://web.dev/get-installed-related-apps/</a> for details.</p>
                            </blockquote>
                            <h3>iOS Installation</h3>
                            <p>iOS requires users to install from the browser menu. Guide users through this process with an install prompt that explains what to do.</p>
                            <div className="grid">
                                <div className="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image33.webp" type="image/webp" />
                                          <source srcSet="images/content/image33.png" type="image/png" /> 
                                          <img src="images/content/image33.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption><span role="img" aria-label="cross">❌</span>  Pop-ups and overlays usually get ignored and dismissed</figcaption>
                                    </figure>
                                </div>
                                <div className="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image28.webp" type="image/webp" />
                                          <source srcSet="images/content/image28.png" type="image/png" /> 
                                          <img src="images/content/image28.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>✔ Native look and feel of the prompt creates a less intrusive experience</figcaption>
                                    </figure>
                                </div>
                                <div className="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image27.webp" type="image/webp" />
                                          <source srcSet="images/content/image27.png" type="image/png" /> 
                                          <img src="images/content/image27.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>✔ Native look and feel of the prompt creates a less intrusive experience</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <h3>Desktop Installation</h3>
                            <p>On desktop, Progressive Web Apps can be installed in Chrome and Edge browsers on all operating systems. An icon appears in the omnibox when the website is installable, and will wiggle to draw users’ attention.&nbsp;</p>
                            <p>Consider providing UI guidance to make sure users are fully aware that they can install the PWA.</p>
                            <div className="grid">
                                <div className="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image16.webp" type="image/webp" />
                                          <source srcSet="images/content/image16.png" type="image/png" /> 
                                          <img src="images/content/image16.webp" alt="pwa book" />
                                        </picture>
                                    </figure>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image26.webp" type="image/webp" />
                                          <source srcSet="images/content/image26.png" type="image/png" /> 
                                          <img src="images/content/image26.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Examples of Install prompt on Desktop</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <ShareIt chapter="4" />
                        <ButtonsChapters next="chapter-5" previous="chapter-3" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
