import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20  ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
    <div className="block-content" id="chapter-7"  ref={ref}>
        <div id="progress-bar" style={{width : finalPercentage+ "%"}}></div>
        <div class="content">
        <div class="inner">
            <BreadCrumb chapter="7"/>
            <div className="entry">
                <h2>Designing Meaningful Notification Experiences</h2>
                <div className="by">By Anna Potanina</div>
                <p>Progressive Web Apps support push notifications on Android, with or without the user installing. Notifications bring great value for businesses: they increase repeated visits and consecutively sales and conversions.&nbsp;</p>
                <p>But, a poor notification experience can affect customer relationships. Asking users to sign up for web notifications when they first arrive will often have very low acceptance rates.&nbsp;</p>
                <p>Instead, wait until users understand the context and see benefit in receiving notifications before prompting for the permission.&nbsp;</p>
                <blockquote>
                    <p>Note: Apps installed from Play automatically enroll users in web notifications. This includes PWAs published in Play using Trusted Web Activities.&nbsp;&nbsp;</p>
                </blockquote>
                <h3>Asking for permission</h3>
                <p>An important part of the notification experience is notifications permission prompt. The best practices are similar to the install prompt.</p>
                <p>✔ <strong>Appear when appropriate</strong></p>
                <p>The ground rule here is not to ask users for permission to send notifications when they first visit the site. Ask for permission at the right moment, when notifications can bring value to the user. Use a pre-permission UI prompt and customize the copy, visuals and style.</p>
                <p>Consider making the prompt discoverable throughout the site, however make sure not to interrupt the user and break the flow.&nbsp; It is better to prompt when the user is either in browsing mode and not deep in a specific task, or when they have just completed a certain action (added product to favorites, completed the purchase, finished reading the page or finished the game).&nbsp;&nbsp;</p>
                <p>Also fix the notifications permissions prompt UI element in the side drawer or within the navigation header, so that it’s always available to the user without being interruptive.</p>
                <p>✔ <strong>Use a value proposition&nbsp;</strong></p>
                <p>Customize the value proposition of notifications according to the context of the current page and actions that the user is completing there. For example: on a product page, the value proposition could be ‘get notified when this item is back in stock’.</p>
                <div class="grid">
                    <div class="col-6">
                    <figure>
                        <picture>
                          <source srcSet="images/content/image22.webp" type="image/webp" />
                          <source srcSet="images/content/image22.png" type="image/png" /> 
                          <img src="images/content/image22.webp" alt="pwa book" />
                        </picture>
                        <figcaption>Example1: a good notification permission looks native to the UI, contains value proposition and visuals, and is presented at a contextually relevant time</figcaption>
                    </figure>
                    </div>
                    <div class="col-6">
                    <figure>
                        <picture>
                          <source srcSet="images/content/image43.webp" type="image/webp" />
                          <source srcSet="images/content/image43.png" type="image/png" /> 
                          <img src="images/content/image43.webp" alt="pwa book" />
                        </picture>
                        <figcaption>Example 2:  One of the placements for notification opt-in and settings should always be available for the user, e.g. in the side menu to allow users to change or cancel the sign-up options</figcaption>
                    </figure>
                    </div>
                </div>
                <h3>Sending Notifications</h3>
                <p>Every notification that is sent should be <strong>timely</strong>, <strong>precise</strong> and <strong>relevant</strong>. That means arriving in a moment when it matters to the user and showing specific information that is relevant to the specific user.</p>
                <p>Every notification consists of three high-level elements.&nbsp;</p>
                <p><strong>Copy</strong> - what the message says and how.&nbsp;</p>
                <ul>
                    <li>The copy consists of the PWA name, message headline and message content&nbsp;</li>
                    <li>The text has to be clear and concise and fit under 40 characters&nbsp;</li>
                    <li>Do not duplicate the app name in the headline (it is already in the header)</li>
                    <li>&nbsp;make sure all information displayed is relevant for the user</li>
                </ul>
                <p><strong>Visuals</strong> - PWA icon or other visual assets&nbsp;</p>
                <ul>
                <li>A PWA icon is a must have as it will visually inform the user which app is sending a message&nbsp;</li>
                <li>Sometimes an image can be added to visualize some other information of the core message: e.g. picture of the sender, photograph of the product that is back in stock, or a photo of a new banking card that has been ordered by the user&nbsp;</li>
                <li>The image and the icon should not duplicate each other&nbsp;</li>
                </ul>
                <p><strong>Actions </strong>(optional) - what action the user can take.&nbsp;</p>
                <ul>
                    <li>Here it is important to let users complete some of the actions based on the information received without opening the app&nbsp;</li>
                    <li>Do not duplicate the actions that are already built into the Android notifications interaction system e.g. default actions “Tap-to-open” and “Dismiss”&nbsp;</li>
                </ul>
                <div class="grid">
                    <div class="col-6 align-center">
                    <figure>
                        <picture>
                          <source srcSet="images/content/image8.webp" type="image/webp" />
                          <source srcSet="images/content/image8.png" type="image/png" /> 
                          <img src="images/content/image8.webp" alt="pwa book" />
                        </picture>
                    </figure>
                    </div>
                </div>
            </div>
            <ShareIt chapter="7"/>
            <ButtonsChapters  next="chapter-8"  previous="chapter-6" />
        </div>
      </div>


    </div>
  </Layout>
  )
};
