import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-8" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div class="content">
                    <div class="inner">
                        <BreadCrumb chapter="8" />
                        <div className="entry">
                            <h2>Measuring Impact</h2>
                            <div className="by">By Martin Schierle</div>
                            <p>A well built Progressive Web App should increase engagement, speed up the page and drive conversions through a meaningful offline mode and installability. So what’s the best way to measure this and determine the impact?&nbsp;</p>
                            <p>As a PWA includes a few different techologies which can be used somewhat independently, it is advised to measure each feature on its own to ensure clear data and a complete picture on what to optimize further.</p>
                            <h3>Measuring Install Rates</h3>
                            <p>Making a Progressive Web App installable is a powerful feature, but success of it is also very dependent on the UX patterns used for promotion - the same as with install prompts for native apps.&nbsp;</p>
                            <p>Therefore, make sure to have the right analytics in place to track installation rates and engagement/conversion rates, so that the flow and UX can be optimized.</p>
                            <p><strong>On Chrome and Microsoft Edge, the entire funnel can be tracked as follows</strong></p>
                            <div class="box-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Step</p>
                                            </td>
                                            <td>
                                                <p>What to measure</p>
                                            </td>
                                            <td>
                                                <p>How to measure</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>1</p>
                                            </td>
                                            <td>
                                                <p># of users eligible to install&nbsp;</p>
                                            </td>
                                            <td>
                                                <p>Listen to <a href="https://developers.google.com/web/fundamentals/app-install-banners#listen_for_beforeinstallprompt">beforeinstallprompt</a> event, which will fire for installable sites when the user meets an engagement heuristic</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>2</p>
                                            </td>
                                            <td>
                                                <p># of users who click on UI install prompt</p>
                                            </td>
                                            <td>
                                                <p>This will depend on how exactly the prompt method is called, but it’ll probably be from a click event on a UI element in the page</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>3</p>
                                            </td>
                                            <td>
                                                <p># of users who accept / decline to install</p>
                                            </td>
                                            <td>
                                                <p>After a user clicks the UI install prompt, the <a href="https://developers.google.com/web/fundamentals/app-install-banners#show_the_prompt">chrome install popup</a> will trigger. Measure whether a user has accepted or rejected in the userChoice property</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>4</p>
                                            </td>
                                            <td>
                                                <p># of users who have installed</p>
                                            </td>
                                            <td>
                                                <p>Successful installation can be measured with the <a href="https://developers.google.com/web/fundamentals/app-install-banners#appinstalled">appinstalled event</a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><strong>For other browsers</strong>&nbsp;</p>
                            <p>For other browsers that support beforeinstallprompt event, similar analytics can be instrumented.</p>
                            <p>For those that don’t (like Safari), it is harder to track PWA installs. In these cases it is recommended to at least measure the usage for the installed PWA as described in the next section.&nbsp;</p>
                            <h3>Measuring Installed Users</h3>
                            <p>It is important to understand the behavior of users that have installed the Progressive Web App.</p>
                            <p>The first thing to track is how many users are opening the PWA from the homescreen (or in general the PWA icon). This can be done by <a href="https://developers.google.com/web/fundamentals/web-app-manifest#start-url">adding a tracking parameter to the launch URL in the manifest</a>, so that homescreen traffic can be drilled into within your analytics package.&nbsp;</p>
                            <p>The PWA can also be accessed when a user clicks on any link (see: <a href="https://developers.google.com/web/fundamentals/integration/webapks#android_intent_filters">deeplinks</a>), such as from a search engine, or also via a <a href="https://web.dev/web-share-target/">web share target</a>. Therefore, it is important to also detect <a href="https://developers.google.com/web/fundamentals/app-install-banners#detect-mode">dynamically through javascript</a> if users are in standalone mode (thereby indicating usage of the installed PWA), and then use this as variable or dimension for your analytics tracking.&nbsp;</p>
                            <h3>TWA Usage and Events</h3>
                            <p>When a Progressive Web App is published in the Play Store using&nbsp; <a href="https://developers.google.com/web/updates/2019/02/using-twa">Trusted Web Activities</a>, many relevant metrics <a href="https://support.google.com/googleplay/android-developer/answer/139628?co=GENIE.Platform%3DDesktop&amp;hl=en">regarding install rates are directly available from the Play Console</a>.</p>
                            <p>Besides this default tracking <a href="https://developers.google.com/web/updates/2019/02/using-twa">there are several ways</a> to pass information into a TWA via URL query parameters, referrer URL or request headers, and all of these can be used to enable additional tracking.</p>
                            <p>Usage within the Trusted Web Activity is recorded via the regular website analytics code, but make sure<a href="https://codelabs.developers.google.com/codelabs/getting-started-with-twas/index.html"> to add a tracking parameter to the default url</a> of the TWA, to enable filtering down to TWA users in analytics.</p>
                            <p><strong>App Campaign attribution</strong></p>
                            <p>App Campaigns can be used to promote a PWA published in Play using Trusted Web Activities.&nbsp;</p>
                            <p>To attribute installs, use <a href="https://developers.google.com/analytics/devguides/collection/android/v4/campaigns#google-play-campaigns">Google Play campaign attribution</a>, or a native app analytics SDK e.g. Google Analytics for Firebase.&nbsp;</p>
                            <p>To attribute more than just the install to the campaign, it’s a little more complex, as what happens in the web part (the TWA) is separate from the native part (what’s installed). <a href="https://support.appsflyer.com/hc/en-us/articles/360002330178-Using-AppsFlyer-with-TWA#inapp-events-with-twa">Here is a specific example</a> on how this can be done with AppsFlyer by passing tracking parameters through URL or headers, combined with server-to-server tracking.&nbsp; Many App Analytics packages support similar server to server integration which can be used to track TWAs accurately.</p>
                            <h3>Measuring Offline &amp; Caching</h3>
                            <p><strong>Offline</strong></p>
                            <p>Offline usage can be measured by recording analytics pings when offline, and then replaying them when connection comes back, as described <a href="https://developers.google.com/web/updates/2016/07/offline-google-analytics#whats_going_on_under_the_hood">here</a>.&nbsp;</p>
                            <p>For Google Analytics this is readily available in a <a href="https://developers.google.com/web/tools/workbox/guides/enable-offline-analytics">module for the Workbox library</a>, and means all analytics events will still be tracked, even when the user goes offline.&nbsp;&nbsp;</p>
                            <p>It may be useful to add an explicit tracking for when the user goes offline or online, by using <a href="https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events">the corresponding browser events</a>. This way allows measurement of how many users that went offline and could be recovered, and how much time is spent offline (by calculating the time between online and offline events and sending this towards your analytics). Keep in mind however, that there will only be a lot of offline activity if there is a good offline experience.</p>
                            <p><strong>Caching &amp; Conversions</strong></p>
                            <p>A service worker can bring more uplift than just offline by handling unreliable network conditions.</p>
                            <p>Therefore, another way to measure impact from improved network resilience and offline is to compare conversion and engagement rates with and without service worker caching as it can be assumed that a more robust and resilient website will convert better. Results should be filtered down on carrier connection and mobile, to see the impact the strongest.&nbsp;</p>
                            <p><a href="https://developers.google.com/web/showcase/2016/service-worker-perf">Here is one way to do such an A/B test</a>, where only a subset of users get the service worker installed via cookie split.</p>
                            <p><strong>Caching &amp; Speed</strong></p>
                            <p>Well defined caching strategies through a service worker can make a website faster on repeat loads. Speed-up through a service worker can be easily measured through common speed tools like <a href="https://developers.google.com/web/tools/lighthouse">Lighthouse</a> or <a href="https://www.webpagetest.org/">Webpagetest.org</a> by measuring once with and once without service worker, and compare both runs. Keep in mind that the service worker is installed on first load, and typically has most impact on page loads after the first, so make sure to measure speed on repeat loads or with prewarmed cache.&nbsp;</p>
                            <p>For field data, use your analytics package of choice, filter on performance metrics for repeat loads or returning users, and compare data before and after service worker launch.&nbsp;</p>
                            <p>Another option here which doesn’t need additional setup is to just monitor field performance through the <a href="https://developers.google.com/web/tools/chrome-user-experience-report">Chrome User Experience Report</a> before and after launch (which contains first and repeat loads, and should therefore show speed uplift through service worker caching).</p>
                            <h3>Measuring Push Notifications</h3>
                            <p>There is a whole funnel to keep track of when measuring push notifications. Similarly to install (and basically most browser permission prompts) it is highly advised to not directly bring up the default permission prompt, but rather use custom UI ahead of time to give the user a real value proposition why they should allow this feature.&nbsp;</p>
                            <p>Tracking and measuring the whole funnel is especially important for notifications, as sub-optimal implementations can easily be perceived as spammy and annoying by users, and therefore may lead to complete abandonment of the Progressive Web App. It may therefore be advised to look closely for engagement metrics of users receiving notifications, to see if there is attrition or churn.</p>
                            <p>With this in mind, the flow to track looks like this:</p>
                            <div class="box-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Step</p>
                                            </td>
                                            <td>
                                                <p>What to measure</p>
                                            </td>
                                            <td>
                                                <p>How to measure</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>1</p>
                                            </td>
                                            <td>
                                                <p># of users eligible for push notifications</p>
                                            </td>
                                            <td>
                                                <p>Detect if push notification is available in the browser through <a href="https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user#feature_detection">feature detection</a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>2</p>
                                            </td>
                                            <td>
                                                <p># of users who click on custom notification UI prompt</p>
                                            </td>
                                            <td>
                                                <p>This will depend on how exactly this feature is offered to the users, but it will probably be from a click event on a UI element in the page</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>3</p>
                                            </td>
                                            <td>
                                                <p># of users who accept / decline push notification permission</p>
                                            </td>
                                            <td>
                                                <p>After a user accepts the custom&nbsp; UI notification prompt, the chrome notification permission will be triggered. This will <a href="https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user#requesting_permission">give back if the user accepted or rejected</a>, which can be tracked in analytics</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>4</p>
                                            </td>
                                            <td>
                                                <p># of users receiving the push notification</p>
                                            </td>
                                            <td>
                                                <p>Whenever a push notification is sent from the backend, the <a href="https://developers.google.com/web/fundamentals/push-notifications/handling-messages#the_push_event">push event</a> is triggered in the service worker on the client side. Use this event handler to <a href="https://developers.google.com/web/fundamentals/push-notifications/handling-messages#wait_until">measure</a> how many users received the push notification successfully</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>5</p>
                                            </td>
                                            <td>
                                                <p># of users who engage with the notification</p>
                                            </td>
                                            <td>
                                                <p>Whenever a notification (or one of the actions within it) is clicked by the users, the <a href="https://developers.google.com/web/fundamentals/push-notifications/notification-behaviour#notification_click_event">notificationclick</a> event is triggered in the service worker. In here, add analytics code to track user engagement. Similarly, there is also a <a href="https://developers.google.com/web/fundamentals/push-notifications/common-notification-patterns#notification_close_event">notification close event</a> to do the same for users dismissing the notification without interacting</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>6</p>
                                            </td>
                                            <td>
                                                <p>Measure conversions and engagement afterwards</p>
                                            </td>
                                            <td>
                                                <p>Most of the time when a notification is clicked, it will open up the PWA. The <a href="https://developers.google.com/web/fundamentals/push-notifications/common-notification-patterns#open_a_window">code to do this can be found here</a>, and can be used to add tracking parameters to the URL to mark the session as started from a notification (e.g. <a href="https://support.google.com/analytics/answer/1033863?hl=en#parameters">via utm_source in Google Analytics</a>). However, depending on the analytics package custom code in the<a href="https://developers.google.com/web/fundamentals/push-notifications/notification-behaviour#notification_click_event">notification click event</a> handler can be used instead of using url parameters</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ShareIt chapter="8" />
                        <ButtonsChapters next="chapter-9" previous="chapter-7" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
