import React, {Component}  from 'react';
import './App.css';
import Home from './pages/home';
import CacheBuster from './component/cacheBuster';
import Notifier from './notifier';

class App extends Component{

  constructor() {
    super();
    this.state = {
      offline: false
    }
  }


  componentDidMount() {

      navigator.onLine ? this.setState({ offline: true }) : this.setState({ offline: false });
      window.addEventListener('online', () => {
        this.setState({ offline: false });
      });
      window.addEventListener('offline', () => {
        this.setState({ offline: true });
      });

  }

  componentDidUpdate() {
    let offlineStatus = !navigator.onLine;
    if (this.state.offline !== offlineStatus) {
      this.setState({ offline: offlineStatus });
    }
  }

 render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
              <div>
                <Notifier offline={this.state.offline} /> 
                <Home/>
              </div>
            );
        }}
      </CacheBuster>
    );
    }
}

export default App;
