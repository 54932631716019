import React from 'react';
import Layout from '../component/layout';
import BreadCrumb from '../component/breadCrumb';
import ButtonsChapters from '../component/buttonsChapters';
import ShareIt from '../component/shareit';
import { useScrollPercentage } from 'react-scroll-percentage'

export default () => {
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    })
    let calculated = (percentage.toPrecision(2) * 100);
    let finalPercentage = calculated < 20 ? 0 : calculated > 80 ? 100 : calculated;
    return (
        <Layout>
            <div className="block-content" id="chapter-5" ref={ref}>
                <div id="progress-bar" style={{ width: finalPercentage + "%" }}></div>
                <div class="content">
                    <div class="inner">
                        <BreadCrumb chapter="5" />
                        <div className="entry">
                            <h2>Designing Installed Experiences</h2>
                            <div className="by">By Anna Potanina</div>
                            <p>Designing a great Progressive Web App experience that meets users’ expectations of an installed app might seem like a brand new activity, but actually it should be quite familiar. UX best practices from both web and native apps can be used as inspiration, and as always, make sure to listen to users and test your design ideas.</p>
                            <h3>Install UX</h3>
                            <p>Designing Installed PWAs starts from the naming and the icon. Once it’s installed, the PWA will look like any other icon on the home screen. If you also have a native app, you might want to think about how you differentiate. You can do that with the icons and the name of the app.&nbsp;</p>
                            <p><strong>Icons</strong></p>
                            <p>For Android, consider designing maskable icons that can adapt to any icon shape required by the phone manufacturer. Read more about designing adaptable icons <a href="https://web.dev/maskable-icon/">here</a>. For iOS use regular <a href="https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/app-icon/">iOS icon design guidelines</a>, but note that iOS does not use the icons from the manifest file, so make sure to <a href="https://web.dev/apple-touch-icon/">add an apple-touch-icon meta tag</a> with the proper image.</p>
                            <p><strong>Naming</strong></p>
                            <p>Using the business name as the name of the PWA makes most sense.</p>
                            <p>If the PWA uses local domains based on user location - e.g. example.co.uk, example.fr, consider including the country in the PWA name. Note that there is no built-in internationalization in the web app manifest, so to do this a different manifest is required for each domain.</p>
                            <p>Adding a suffix “Lite” to the name may be a good way to differentiate between the PWA and the native app, in case both are available.</p>
                            <p><strong>Launching</strong></p>
                            <p>When the app is launched from the homescreen, Android's default behavior is to display a white screen until the PWA is ready. The user may see a blank, white screen for up to 200ms. However, the splash screen can be customized to provide a smooth launch experience. Chrome for Android automatically customizes the splash screen so long as the manifest contains:</p>
                            <ul>
                                <li>The name property</li>
                                <li>The background_color property is set to a valid CSS color value</li>
                                <li>The icons array specifies an icon that is at least 512px by 512px</li>
                                <li>The icon exists and is a PNG</li>
                            </ul>
                            <p>For iOS there is no splash screen customization at the moment, so <a href="https://www.netguru.com/codestories/few-tips-that-will-make-your-pwa-on-ios-feel-like-native">implement meta-tags</a> to specify pre-generated splash screens.</p>
                            <h3>Standalone UI</h3>
                            <p>Progressive Web Apps launched from the homescreen icon should open in a standalone display mode, to provide the experience consistent to what users have come to expect from native apps. This means the PWA launches with no browser bar on the top, and is separate from the browser when cycling through the app switcher.&nbsp;</p>
                            <p><strong>Navigation</strong></p>
                            <p>When designing for a longer screen, make sure all key navigation items are easily accessible for the user. In general, the bottom of the screen on mobile devices is considered to be a more ergonomic location: easier to reach when holding the device with just one hand. Consider locating there the key calls-to-action, testing bottom navigation or anchoring modal pop-ups on the bottom (vs in the center of the screen).</p>
                            <div class="grid">
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image18.webp" type="image/webp" />
                                          <source srcSet="images/content/image18.png" type="image/png" /> 
                                          <img src="images/content/image18.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Designing for standalone: modal pop-ups anchored to the bottom</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image38.webp" type="image/webp" />
                                          <source srcSet="images/content/image38.png" type="image/png" /> 
                                          <img src="images/content/image38.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Designing for standalone: Form completion with bottom-anchored navigation</figcaption>
                                    </figure>
                                </div>
                                <div class="col-4">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image10.webp" type="image/webp" />
                                          <source srcSet="images/content/image10.png" type="image/png" /> 
                                          <img src="images/content/image10.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Designing for standalone: Bottom navigation panel</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p>Along with an increased above the fold space, PWAs in standalone mode have fewer browser navigation capabilities. For example, on iOS there are no <strong>‘’Back” / “Forward” </strong>buttons, so implement additional navigation elements within the PWA such as a “Back” button, breadcrumbs, additional navigation bar, etc.</p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image4.webp" type="image/webp" />
                                          <source srcSet="images/content/image4.png" type="image/png" /> 
                                          <img src="images/content/image4.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Adding “Back” button for standalone mode</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Color schemes</strong></p>
                            <p>On Android, the browser bar can be changed to match your brand color. This will also affect the standalone mode, specifically the color of the system notifications panel.</p>
                            <div class="grid">
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image35.webp" type="image/webp" />
                                          <source srcSet="images/content/image35.png" type="image/png" /> 
                                          <img src="images/content/image35.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example browser mode: brand-colored browser bar and system notifications panel</figcaption>
                                    </figure>
                                </div>
                                <div class="col-6">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image46.webp" type="image/webp" />
                                          <source srcSet="images/content/image46.png" type="image/png" /> 
                                          <img src="images/content/image46.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Example standalone mode: brand-colored system notifications panel</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <p><strong>Refreshing</strong></p>
                            <p>Pull-to-refresh is an intuitive gesture popularized by native apps such as Facebook and Twitter. For installed PWAs, consider customizing pull-to-refresh and overscroll behavior. Read more <a href="https://developers.google.com/web/updates/2017/11/overscroll-behavior">here.</a></p>
                            <p><strong>Sharing</strong></p>
                            <p>When users are in standalone mode on both Android and iOS, the browser menu isn’t available to select the URL and easily share. Consider displaying additional buttons that trigger the <a href="https://web.dev/web-share/">Web Share API</a> to bring up the native Android share dialogue and make it easy to share URLs via other apps, like social networks or messengers. </p>
                            <div class="grid">
                                <div class="col-6 align-center">
                                    <figure>
                                        <picture>
                                          <source srcSet="images/content/image30.webp" type="image/webp" />
                                          <source srcSet="images/content/image30.png" type="image/png" /> 
                                          <img src="images/content/image30.webp" alt="pwa book" />
                                        </picture>
                                        <figcaption>Adding “Share” button for the standalone mode</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <ShareIt chapter="5" />
                        <ButtonsChapters next="chapter-6" previous="chapter-4" />
                    </div>
                </div>
            </div>
        </Layout>
    )
};
