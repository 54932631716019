import React from 'react';

export default ({show, onClickClose}) => {
    return (
        <div onClick={onClickClose} className={`box-popup   ${show ? "open" : ""}`} >
            <div className="inner">
                <div className="content">
                    <p>Install this web app on your iPhone for free. Tap <svg height="25" width="25" viewBox="0 0 50 50"><path fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M17 10l8-8 8 8M25 32V2.333"/><path fill="none" d="M0 0h50v50H0z"/><path d="M17 17H8v32h34V17h-9" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/></svg> and then Add to Home Screen.</p>
                </div>
            </div>
        </div>
    );
}
