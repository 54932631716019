import React, {Component} from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import ShareIt from '../component/shareit';
import ListIndex from '../component/listIndex';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isInstalled: true,
        };
    }


    componentDidMount () {
        var self = this;

        window.addEventListener('beforeinstallprompt', (e) => {
            self.setState({isInstalled: false})
        });

    }


    render(){
    return (
        <div>
        <Header />
            <div className="block-header">
                <picture className="bg-cover" >
                    <source media="(max-width: 600px)" srcSet="images/cover-mobile.jpg" />
                    <source media="(max-width: 1200px)" srcSet="images/cover-desktop.jpg" />
                    <img src="images/cover-desktop.jpg" alt="pwa book" />
                </picture>
                <div className="inner">
                    <div className="box-info">
                        <div className="row"><h4>Progressive Web Apps</h4></div>
                        <div className="row">
                            <h1>The Superpowers of The Web and Native Apps Combined</h1>
                        </div>
                        <div className="row" onClick={()=>{
                            let ele = document.getElementById('show-modal-button')
                            ele.click()
                        }}>
                        {!this.state.isInstalled &&
                            <div className="button stick style2 js-download" data-id="lightbox-download">
                            <strong>Install</strong>
                            <em>PWA</em>
                            and read anytime
                            </div>
                        }

                            <h4>by Google & Awwwards</h4>
                        </div>

                    </div>
                    <a href="#index" className="bt-down" aria-label="Down">
                        <svg width="40" viewBox="0 0 100 100"><path d="M73.4 50.4l1.6 1.5L48.8 78 22.6 51.9l1.6-1.5 23.5 23.5V20.2h2.2v53.7z"/></svg>
                    </a>
                </div>
                <div className="box-abs-right">
                    <div className="box-brain">
                    <strong>Brain Food</strong>
                    <span>Vol 6</span>
                    </div>
                </div>
                <div id="index"></div>
            </div>
            <div className="block-content">
                <div className="content">
                    <div className="inner">
                        <div className="breadcrumb">
                            <div className="box-left">
                                <strong><a href="https://www.awwwards.com/books/">Ebooks</a></strong> / PWA Book 2
                            </div>
                            <div className="box-right">
                            <ShareIt />
                            </div>
                        </div>
                        <div className="entry">
                            <p>We return to take a closer look at Progressive Web Apps (PWAs) and how they combine the reach of the web with the device integrations of installed software. In <a href="https://www.awwwards.com/PWA-ebook/">Volume 1</a> we learnt the unique features PWAs offer users, how to build one, and how to assess the results.</p>
                        </div>
                        <ListIndex />
                    </div>
                </div>
            </div>

            <Footer/>
        </div>

    )

}
};